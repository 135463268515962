import { useContext, useState, useEffect } from 'react'
import { styled } from '@mui/material/styles'
import { Form, Field } from 'react-final-form'
import { useMutation } from '@apollo/client'
import * as yup from 'yup'
import { Redirect } from 'react-router-dom'
import { observer } from 'mobx-react-lite'

import { Button, TextField } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'

import formUtil from 'helpers/form'
import { Alert } from 'components'
import AppContext from 'stores'
import { LOGIN } from '../queries'

// import debug from 'debug'
// const log = debug('@sirtoko:views:LoginForm')

const PREFIX = 'LoginForm'

const classes = {
  fields: `${PREFIX}-fields`,
  submitButton: `${PREFIX}-submitButton`,
}

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.fields}`]: {
    margin: theme.spacing(-1),
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      flexGrow: 1,
      margin: theme.spacing(1),
    },
  },
}))

const schema: any = yup.object({
  username: yup.string().required('Username is required'),
  password: yup.string().required('Password is required'),
})

const LoginForm = observer(() => {
  const app: any = useContext(AppContext)

  const [loggingIn, setLoggingIn] = useState(false)
  const [loggedIn, setLoggedIn] = useState(false)
  const [loginError, setLoginError] = useState()

  const [login] = useMutation(LOGIN, {})

  const submit = (values: any, form: any) => {
    setLoggingIn(true)
    const { username, password } = values
    login({ variables: { username, password } })
      .then(({ data }) => {
        const { token, error } = data.login
        if (error) {
          setLoginError(error)
          setLoggingIn(false)
          return
        }
        app.user.login(username, token)
      })
      .catch(() => {
        setLoggingIn(false)
      })
    form.initialize(values)
  }

  useEffect(() => {
    if (app.user.username && app.user.token && !loggedIn) {
      setLoggedIn(true)
    }
  }, [app.user.token, app.user.username, loggedIn])

  const initialValues = {}

  if (loggedIn) {
    return <Redirect to="/" />
  }

  return (
    <Root>
      <Form
        validate={(values: any) => formUtil.validate(values, schema)}
        onSubmit={submit}
        initialValues={initialValues}
        render={({ form, handleSubmit }) => {
          const formState = form.getState()
          return (
            <form onSubmit={handleSubmit}>
              <div className={classes.fields}>
                <Field name="username">
                  {({ input, meta }) => (
                    <>
                      <TextField
                        {...input}
                        fullWidth
                        error={formUtil.hasError(meta)}
                        helperText={formUtil.helperText(meta)}
                        label="Username"
                        variant="outlined"
                      />
                    </>
                  )}
                </Field>
                <Field name="password">
                  {({ input, meta }) => (
                    <>
                      <TextField
                        {...input}
                        type="password"
                        fullWidth
                        error={formUtil.hasError(meta)}
                        helperText={formUtil.helperText(meta)}
                        label="Password"
                        variant="outlined"
                      />
                    </>
                  )}
                </Field>

                {loginError && <Alert variant="error" message={loginError} />}

                {loggingIn ? (
                  <div>
                    <CircularProgress />
                  </div>
                ) : (
                  <Button
                    className={classes.submitButton}
                    sx={{
                      marginTop: (theme) => theme.spacing(2),
                      width: '100%',
                    }}
                    color="primary"
                    disabled={!formState.valid || loggingIn}
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Sign in
                  </Button>
                )}
              </div>
            </form>
          )
        }}
      />
    </Root>
  )
})

export default LoginForm
