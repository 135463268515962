import { ValidationError } from 'yup'
import { setIn } from 'final-form'

const validate = (values: any, schema: any) =>
  new Promise((resolve) => {
    if (typeof schema === 'function') {
      schema = schema()
    }
    schema
      .validate(values, { abortEarly: false })
      .then(() => resolve(null))
      .catch((err: ValidationError) => {
        const errResult = err.inner.reduce((errors: { [x: string]: string }, error: any) => {
          return setIn(errors, error.path, error.message)
        }, {})
        resolve(errResult)
      })
  })

// Field Helpers

const hasError = (fieldMeta: any) => fieldMeta.error && fieldMeta.touched

const helperText = (fieldMeta: any) => (hasError(fieldMeta) ? fieldMeta.error : null)

const form = {
  validate,
  hasError,
  helperText,
}

export default form
