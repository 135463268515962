import React from 'react'

const CacheContext = React.createContext<Partial<any>>({})

export const CacheProvider = (props: any) => {
  const { children } = props

  const store: any = new Map(/*[
    [
      'product.bom.add_created',
      '204',
    ],
  ]*/)

  return <CacheContext.Provider value={store}>{children}</CacheContext.Provider>
}

export default CacheContext
