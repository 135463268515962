import PropTypes from 'prop-types'
import { observer, useLocalStore } from 'mobx-react-lite'
import { useQuery } from '@apollo/client'

import { styled } from '@mui/material/styles'
import {
  Button,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Typography,
  colors,
} from '@mui/material'

import Picker from './Picker'
import { GET_PRODUCTS } from './queries'

const PREFIX = 'ProductPicker'

const classes = {
  root: `${PREFIX}-root`,
  header: `${PREFIX}-header`,
  actions: `${PREFIX}-actions`,
  tabs: `${PREFIX}-tabs`,
  tabContent: `${PREFIX}-tabContent`,
}

const StyledTypography = styled(Typography)(({ theme }) => ({
  [`& .${classes.root}`]: {
    height: '90vh',
  },

  [`& .${classes.header}`]: {
    padding: theme.spacing(3),
    maxWidth: 720,
    margin: '0 auto',
  },

  [`& .${classes.actions}`]: {
    backgroundColor: colors.grey[100],
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
  },

  [`& .${classes.tabs}`]: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },

  [`& .${classes.tabContent}`]: {
    minHeight: '90vh',
  },
}))

const ProductPicker = observer((props: any) => {
  const { visible, onOk, onCancel /*hiddenIds, initial, initialTab*/ } = props

  // const src = useAsObservableSource({ tab: initialTab || 0 })
  const store = useLocalStore(() => ({
    selectedRowKeys: [],
    selected: new Map(),
    quantities: new Map(),
    filter: null,
    // ...src,
  }))

  // const tabs = [
  //   { value: 'picker', label: 'Picker' },
  //   { value: 'categories', label: 'Categories' },
  //   { value: 'lists', label: 'Lists' },
  // ]

  // const handleTabsChange = (event: any, value: any) => {
  //   store.tab = value
  // }

  const clear = () => {
    store.selected = new Map()
    store.quantities = new Map()
  }

  const handleOk = () => {
    const sel = [...store.selected.values()]
    onOk(sel)
    clear()
  }

  const handleCancel = () => {
    onCancel()
    clear()
  }

  const { data: productsData, loading: productsLoading } = useQuery(GET_PRODUCTS, {
    fetchPolicy: 'no-cache',
    variables: { query: {} },
  })
  let products: any[] = []
  if (productsData) {
    products = productsData.products
  }

  const onPickerResults = (results: any) => {
    results.forEach((r: any) => {
      const { product } = r
      store.selected.set(product.id, r)
      store.quantities.set(product.id, r.quantity)
    })
  }

  return (
    <Dialog fullWidth maxWidth="md" open={visible || false} onClose={() => handleCancel()}>
      <DialogTitle>Add Products</DialogTitle>
      <DialogContent className={classes.root}>
        <Grid container spacing={2}>
          <Grid item sm={12}>
            <Picker
              store={store}
              onResults={onPickerResults}
              products={products}
              loading={productsLoading}
            />
          </Grid>
          {
            // <Grid item md={2}>
            //   <Tabs
            //     orientation="vertical"
            //     variant="scrollable"
            //     className={classes.tabs}
            //     scrollButtons="auto"
            //     value={store.tab}
            //     onChange={handleTabsChange}
            //   >
            //     {tabs.map((tb, index) => (
            //       <Tab key={tb.value} label={tb.label} value={index} />
            //     ))}
            //   </Tabs>
            // </Grid>
            // <Grid item md={10} className={classes.tabContent}>
            //   <TabPanel value={store.tab} index={0}>
            //     <Picker
            //       store={store}
            //       onResults={onPickerResults}
            //       products={products}
            //       loading={productsLoading}
            //     />
            //   </TabPanel>
            //   <TabPanel value={store.tab} index={1}>
            //     <CategoriesPane
            //       store={store}
            //       onOk={onOk}
            //       onCancel={onCancel}
            //       hiddenIds={hiddenIds}
            //       initial={initial}
            //     />
            //   </TabPanel>
            //   <TabPanel value={store.tab} index={2}>
            //     <ListsPane
            //       store={store}
            //       onOk={onOk}
            //       onCancel={onCancel}
            //       hiddenIds={hiddenIds}
            //       initial={initial}
            //     />
            //   </TabPanel>
            // </Grid>
          }
        </Grid>

        {/*process.env.NODE_ENV === 'development' && (
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <pre>disabled: {JSON.stringify(hiddenIds, null, 2)}</pre>
            </Grid>

            <Grid item xs={4}>
              <pre>selected: {JSON.stringify(store.selected, null, 2)}</pre>
            </Grid>

            <Grid item xs={4}>
              <pre>quantities: {JSON.stringify(store.quantities, null, 2)}</pre>
            </Grid>
          </Grid>
        )*/}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          Cancel
        </Button>
        <Button disabled={store.selected.size < 1} onClick={handleOk} color="primary">
          Add
        </Button>
      </DialogActions>
    </Dialog>
  )
})

function TabPanel(props: any) {
  const { children, value, index, ...other } = props

  return (
    <StyledTypography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      <Box>{children}</Box>
    </StyledTypography>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

export default ProductPicker
