import React from 'react'

import AppContext from 'stores'

// import debug from 'debug'
// const log = debug('@sirtoko:services:accessControl')

export const AccessControlContext = React.createContext(null)

const check = (rules: any, role: any, action: any, data: any) => {
  if (action === '*') {
    return true
  }

  const permissions = rules[role]
  if (!permissions) {
    return false
  }

  const staticPermissions = permissions.static
  if (staticPermissions && staticPermissions.includes(action)) {
    return true
  }

  const dynamicPermissions = permissions.dynamic
  if (dynamicPermissions) {
    const permissionCondition = dynamicPermissions[action]
    if (!permissionCondition) {
      return false
    }
    return permissionCondition(data)
  }
  return false
}

export const AccessControlProvider = (props: any) => {
  const { children, rules } = props
  const { user } = React.useContext(AppContext)

  // can results in true when any user roles is authorized
  const can = (action: any, data: any) => {
    let result = false
    user.roles.forEach((role: any) => {
      if (!result) {
        result = check(rules, role, action, data)
      }
    })
    return result
  }

  const store: any = {
    rules,
    can,
  }

  return <AccessControlContext.Provider value={store}>{children}</AccessControlContext.Provider>
}

export default AccessControlProvider
