import { Theme } from '@mui/material/styles'
import { createTheme, adaptV4Theme } from '@mui/material/styles'

import palette from './palette'
import typography from './typography'
import overrides from './overrides'

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const theme = createTheme(
  adaptV4Theme({
    palette,
    typography,
    overrides,
  }),
)

export default theme
