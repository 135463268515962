import { colors } from '@mui/material'

const white = '#FFFFFF'
const black = '#000000'
// const main = '#001529'

/* eslint-disable dot-notation */

const palette = {
  black,
  white,
  primary: {
    contrastText: white,
    dark: colors.lightBlue[900],
    main: colors.lightBlue[900],
    light: colors.lightBlue[700],
  },
  secondary: {
    contrastText: colors.grey[800],
    dark: colors.grey[400],
    main: colors.grey[300],
    light: colors.grey[200],
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400],
  },
  text: {
    primary: colors.blueGrey[900],
    secondary: colors.blueGrey[600],
    link: colors.blue[600],
  },
  link: colors.blue[800],
  icon: colors.blueGrey[600],
  background: {
    default: '#F4F6F8',
    paper: white,
  },
  divider: colors.grey[200],
}
export default palette
