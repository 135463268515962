import React from 'react'
import { useLocalStore } from 'mobx-react-lite'
import store from 'store2'

import debug from 'debug'
const log = debug('@sirtoko:components:AppStore')

const AppContext = React.createContext<Partial<any>>({})

const tmpRoles: any = {
  'eatsmylifesg@gmail.com': ['admin'],
  'eatsmylifeoth@gmail.com': ['ops'],
}

if (process.env.NODE_ENV === 'development') {
  tmpRoles.username = ['admin']
}

export const AppStoreProvider = (props: any) => {
  const { children } = props

  const authUser = store.get('auth_user')
  const authToken = store.get('auth_token')

  const login = (username: string, token: string) => {
    log('Login')
    state.user.username = username
    state.user.token = token
    store.set('auth_user', username)
    store.set('auth_token', token)
  }

  const logout = () => {
    log('Logout')
    state.user.username = undefined
    state.user.token = undefined
    store.remove('auth_token')
    store.remove('auth_user')
  }

  const state = useLocalStore(() => ({
    user: {
      username: authUser,
      token: authToken,
      get roles() {
        return tmpRoles[authUser] || []
      },
      logout,
      login,
    },
    referenceUnits: {
      all: null,
      commonMeasures: null,
    },
  }))

  return <AppContext.Provider value={state}>{children}</AppContext.Provider>
}

export default AppContext
