import { forwardRef } from 'react'
import PropTypes from 'prop-types'

import { Box, Paper, IconButton, Typography, colors } from '@mui/material'
import { styled } from '@mui/material/styles'
import CheckCircleIcon from '@mui/icons-material/CheckCircleOutlined'
import CloseIcon from '@mui/icons-material/CloseOutlined'
import ErrorIcon from '@mui/icons-material/ErrorOutlined'
import InfoIcon from '@mui/icons-material/InfoOutlined'
import WarningIcon from '@mui/icons-material/WarningOutlined'

const PREFIX = 'Alert'

const css = {
  root: `${PREFIX}-root`,
  default: `${PREFIX}-default`,
  success: `${PREFIX}-success`,
  info: `${PREFIX}-info`,
  warning: `${PREFIX}-warning`,
  error: `${PREFIX}-error`,
  message: `${PREFIX}-message`,
  icon: `${PREFIX}-icon`,
  action: `${PREFIX}-action`,
}

const Root = styled(Box)(({ theme }) => ({
  [`& .${css.default}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },

  [`& .${css.success}`]: {
    backgroundColor: colors.green[600],
    color: theme.palette.common.white,
  },

  [`& .${css.info}`]: {
    backgroundColor: colors.lightBlue[600],
    color: theme.palette.common.white,
  },

  [`& .${css.warning}`]: {
    backgroundColor: colors.orange[900],
    color: theme.palette.common.white,
  },

  [`& .${css.error}`]: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },

  [`& .${css.message}`]: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    padding: '8px 0',
  },

  [`& .${css.icon}`]: {
    fontSize: 20,
    marginRight: theme.spacing(1),
  },

  [`& .${css.action}`]: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
    paddingLeft: 16,
    marginRight: -8,
  },
}))

const icons: { [x: string]: any } = {
  default: <InfoIcon />,
  success: <CheckCircleIcon />,
  info: <InfoIcon />,
  warning: <WarningIcon />,
  error: <ErrorIcon />,
}

const Alert = forwardRef((props: any, ref: any) => {
  const { className, icon, variant, message, onClose, ...rest } = props

  return (
    <Root>
      <Paper
        {...rest}
        component={Typography}
        elevation={1}
        ref={ref}
        sx={{
          display: 'flex',
          alignItems: 'center',
          padding: '6px 16px',
          borderRadius: (theme) => theme.shape.borderRadius,
        }}
      >
        <span className={css.icon}>{icon || icons[variant]}</span>
        <span className={css.message}>{message}</span>
        {onClose && (
          <IconButton
            className={css.action}
            color="inherit"
            key="close"
            onClick={onClose}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        )}
      </Paper>
    </Root>
  )
})

Alert.displayName = 'Alert'

Alert.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.node,
  message: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['default', 'info', 'success', 'warning', 'error']),
}

Alert.defaultProps = {
  variant: 'default',
}

export default Alert
