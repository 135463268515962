import { useState } from 'react'
import { Box, Paper, Button, Input, InputAdornment, IconButton } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import ClearIcon from '@mui/icons-material/Clear'

interface SearchBarProps {
  onSearch: (term: string | null) => void
  beforeSearch?: () => React.ReactNode
}

const SearchBar = (props: SearchBarProps) => {
  const { onSearch, ...rest } = props

  const [term, setTerm] = useState('')

  const handleChange = (e: any) => {
    setTerm(e.target.value)
  }

  return (
    <Box
      sx={{
        flexGrow: 1,
        maxWidth: 480,
        flexBasis: 480,
        display: 'flex',
        alignItems: 'center',
      }}
      {...rest}
    >
      <Paper
        sx={(theme) => ({
          flexGrow: 1,
          height: 36,
          padding: theme.spacing(0, 2),
          display: 'flex',
          alignItems: 'center',
        })}
        elevation={1}
      >
        <SearchIcon
          sx={(theme) => ({
            marginRight: theme.spacing(2),
          })}
        />
        <Input
          sx={{
            flexGrow: 1,
          }}
          disableUnderline
          placeholder="Search"
          onChange={handleChange}
          onKeyPress={(ev: any) => {
            if (ev.key === 'Enter') {
              onSearch(ev.target.value)
            }
          }}
          value={term}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle"
                onClick={() => {
                  setTerm('')
                  onSearch(null)
                }}
              >
                {term ? <ClearIcon /> : null}
              </IconButton>
            </InputAdornment>
          }
        />
      </Paper>
      <Button
        sx={{
          marginLeft: (theme) => theme.spacing(2),
        }}
        onClick={() => onSearch(term)}
        variant="contained"
      >
        Search
      </Button>
    </Box>
  )
}

export default SearchBar
