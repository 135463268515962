import React from 'react'
import { SnackbarProvider } from 'notistack'
import { configure } from 'mobx'
// @ts-ignore
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
// @ts-ignore
import { CookiesProvider } from 'react-cookie'

import { ThemeProvider, Theme, StyledEngineProvider } from '@mui/material/styles'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'

import { AccessControlProvider } from './services'
import { AppStoreProvider } from './stores'
import { CacheProvider } from 'components/CacheContext'
import accessRules from './rules'

import Error404 from './pages/404'
import { UnauthenticatedApp } from './layouts/Unauthenticated'
import { AuthenticatedApp } from './layouts/Authenticated'
import Login from './pages/user/login'

import './global.scss'
import theme from './theme/index'

declare module '@mui/styles' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const Home = React.lazy(() => import('./pages/user/home'))
const Products = React.lazy(() => import('./pages/products'))
const ProductCategories = React.lazy(() => import('./pages/productCategories'))
const Suppliers = React.lazy(() => import('./pages/suppliers'))
const Lists = React.lazy(() => import('./pages/productLists'))
const Settings = React.lazy(() => import('./pages/settings'))
const Usage = React.lazy(() => import('./pages/usage'))
const Costing = React.lazy(() => import('./pages/costing'))
const Boms = React.lazy(() => import('./pages/boms'))
const BomCategories = React.lazy(() => import('./pages/bomCategories'))

configure({
  enforceActions: 'never',
})

function App() {
  return (
    <CookiesProvider>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <SnackbarProvider maxSnack={3}>
              <AppStoreProvider>
                <CacheProvider>
                  <AccessControlProvider rules={accessRules}>
                    <Router>
                      <Switch>
                        <UnauthenticatedApp exact path="/user/login" component={Login} />
                        <AuthenticatedApp path="/boms/categories" component={BomCategories} />
                        <AuthenticatedApp path="/boms" component={Boms} />
                        <AuthenticatedApp
                          path="/products/categories"
                          component={ProductCategories}
                        />
                        <AuthenticatedApp path="/products/suppliers" component={Suppliers} />
                        <AuthenticatedApp path="/products" component={Products} />
                        <AuthenticatedApp path="/lists" component={Lists} />
                        <AuthenticatedApp path="/settings" component={Settings} />
                        <AuthenticatedApp path="/usage" component={Usage} />
                        <AuthenticatedApp path="/costing" component={Costing} />
                        <AuthenticatedApp exact path="/" component={Home} />
                        <Route component={Error404} />
                      </Switch>
                    </Router>
                  </AccessControlProvider>
                </CacheProvider>
              </AppStoreProvider>
            </SnackbarProvider>
          </LocalizationProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </CookiesProvider>
  )
}

export default App
