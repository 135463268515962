import { styled } from '@mui/material/styles'
import { Button, Typography, TextField, Paper, MenuItem } from '@mui/material'
import Select from 'react-select'
import clsx from 'clsx'

import { Label } from 'components'

import debug from 'debug'

const PREFIX = 'AutoComplete'

const css = {
  root: `${PREFIX}-root`,
  input: `${PREFIX}-input`,
  valueContainer: `${PREFIX}-valueContainer`,
  noOptionsMessage: `${PREFIX}-noOptionsMessage`,
  singleValue: `${PREFIX}-singleValue`,
  placeholder: `${PREFIX}-placeholder`,
  paper: `${PREFIX}-paper`,
  divider: `${PREFIX}-divider`,
  option: `${PREFIX}-option`,
  optionSelected: `${PREFIX}-optionSelected`,
  productCategory: `${PREFIX}-productCategory`,
  createProduct: `${PREFIX}-createProduct`,
}
const log = debug('@sirtoko:costing')
log.enabled = true

const StyledSelect = styled(Select)(({ theme }) => ({
  [`& .${css.root}`]: {
    flexGrow: 1,
    minWidth: 290,
    width: '100%',
  },

  [`& .${css.input}`]: {
    display: 'flex',
  },

  [`& .${css.valueContainer}`]: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
  },

  [`& .${css.noOptionsMessage}`]: {
    padding: theme.spacing(1, 2),
  },

  [`& .${css.singleValue}`]: {
    fontSize: 16,
  },

  [`& .${css.placeholder}`]: {},

  [`& .${css.paper}`]: {
    position: 'absolute',
    zIndex: 1200,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },

  [`& .${css.divider}`]: {
    height: theme.spacing(2),
  },

  [`& .${css.option}`]: {
    fontWeight: 400,
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
  },

  [`& .${css.optionSelected}`]: {
    fontWeight: 500,
  },

  [`& .${css.productCategory}`]: {
    marginLeft: theme.spacing(1),
  },

  [`& .${css.createProduct}`]: {
    margin: theme.spacing(1),
  },
}))

function NoOptionsMessage(props: any) {
  const { innerProps } = props

  return (
    <Typography color="textSecondary" className={css.noOptionsMessage} {...innerProps}>
      Not found
    </Typography>
  )
}

function inputComponent({ inputRef, ...props }: any) {
  return <div ref={inputRef} {...props} />
}

function Control(props: any) {
  const {
    children,
    innerProps,
    innerRef,
    selectProps: { TextFieldProps },
  } = props

  return (
    <TextField
      variant="outlined"
      className={css.root}
      InputProps={{
        inputComponent,
        inputProps: {
          className: css.input,
          ref: innerRef,
          children,
          ...innerProps,
        },
      }}
      {...TextFieldProps}
    />
  )
}

function Option(props: any) {
  const { data, innerProps, innerRef, isFocused, isSelected } = props

  return (
    <MenuItem
      ref={innerRef}
      selected={isFocused}
      component="div"
      className={clsx(css.option, isSelected ? css.optionSelected : null)}
      {...innerProps}
    >
      {data.name}
      {data.category && (
        <Label className={css.productCategory} variant="contained">
          {data.category.name}
        </Label>
      )}
    </MenuItem>
  )
}

function Placeholder(props: any) {
  const { innerProps = {}, children } = props

  return (
    <Typography color="textSecondary" className={css.placeholder} {...innerProps}>
      {children}
    </Typography>
  )
}

function SingleValue(props: any) {
  const { children, innerProps } = props

  return (
    <Typography className={css.singleValue} {...innerProps}>
      {children}
    </Typography>
  )
}

function ValueContainer(props: any) {
  const { children } = props

  return <div className={css.valueContainer}>{children}</div>
}

function Menu(props: any) {
  const { children, innerProps } = props

  return (
    <Paper square className={css.paper} {...innerProps}>
      {children}
      <Button
        className={css.createProduct}
        disableElevation
        variant="contained"
        size="small"
        color="primary"
      >
        Create Product
      </Button>
    </Paper>
  )
}

const components = {
  Control,
  DropdownIndicator: () => null,
  Menu,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
}

type AutoCompleteProps = {
  options: any
  selectRef?: any
  onChange?: any
  [x: string]: any
}

const AutoComplete = (props: AutoCompleteProps) => {
  const { onChange, selectRef, ...rest } = props

  const getOptionValue = (opt: any) => (opt ? opt.id : null)
  const getOptionLabel = (opt: any) => (opt ? opt.name : null)

  const handleOnChange = (val: any, event: any) => {
    if (val) {
      onChange(getOptionValue(val))
    }
  }

  return (
    <StyledSelect
      className="product-select"
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      isClearable
      openMenuOnClick={false}
      onChange={handleOnChange}
      placeholder="Product"
      components={components}
      ref={selectRef}
      {...rest}
    />
  )
}

export default AutoComplete
