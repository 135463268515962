import { colors } from '@mui/material'

const MuiLinearProgress = {
  root: {
    borderRadius: 3,
    overflow: 'hidden',
  },
  colorPrimary: {
    backgroundColor: colors.blueGrey[50],
  },
}
export default MuiLinearProgress
