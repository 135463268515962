const rules = {
  '*': {},
  ops: {
    static: ['ops:*'],
  },
  admin: {
    static: ['admin:*', 'ops:*'],
  },
}

export default rules
