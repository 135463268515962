import { Link as RouterLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import moment from 'moment'
import { styled } from '@mui/material/styles'
import { Avatar, List, ListItem, ListItemAvatar, ListItemText } from '@mui/material'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import PaymentIcon from '@mui/icons-material/Payment'
import PeopleIcon from '@mui/icons-material/PeopleOutlined'
import CodeIcon from '@mui/icons-material/Code'
import StoreIcon from '@mui/icons-material/Store'

import gradients from 'utils/gradients'

const PREFIX = 'NotificationList'

const classes = {
  root: `${PREFIX}-root`,
  listItem: `${PREFIX}-listItem`,
  avatarBlue: `${PREFIX}-avatarBlue`,
  avatarGreen: `${PREFIX}-avatarGreen`,
  avatarOrange: `${PREFIX}-avatarOrange`,
  avatarIndigo: `${PREFIX}-avatarIndigo`,
  arrowForwardIcon: `${PREFIX}-arrowForwardIcon`,
}

const StyledList = styled(List)(({ theme }) => ({
  [`&.${classes.root}`]: {},

  [`& .${classes.listItem}`]: {
    '&:hover': {
      backgroundColor: theme.palette.background.default,
    },
  },

  [`& .${classes.avatarBlue}`]: {
    backgroundImage: gradients.blue,
  },

  [`& .${classes.avatarGreen}`]: {
    backgroundImage: gradients.green,
  },

  [`& .${classes.avatarOrange}`]: {
    backgroundImage: gradients.orange,
  },

  [`& .${classes.avatarIndigo}`]: {
    backgroundImage: gradients.indigo,
  },

  [`& .${classes.arrowForwardIcon}`]: {
    // color: theme.palette.icon,
  },
}))

const NotificationList = (props: any) => {
  const { notifications, className, ...rest } = props

  const avatars: any = {
    order: (
      <Avatar className={classes.avatarBlue}>
        <PaymentIcon />
      </Avatar>
    ),
    user: (
      <Avatar className={classes.avatarOrange}>
        <PeopleIcon />
      </Avatar>
    ),
    project: (
      <Avatar className={classes.avatarGreen}>
        <StoreIcon />
      </Avatar>
    ),
    feature: (
      <Avatar className={classes.avatarIndigo}>
        <CodeIcon />
      </Avatar>
    ),
  }

  return (
    <StyledList {...rest} className={clsx(classes.root, className)} disablePadding>
      {notifications.map((notification: any, i: number) => (
        <ListItem
          className={classes.listItem}
          component={RouterLink}
          divider={i < notifications.length - 1}
          key={notification.id}
          to="#"
        >
          <ListItemAvatar>{avatars[notification.type]}</ListItemAvatar>
          <ListItemText
            primary={notification.title}
            primaryTypographyProps={{ variant: 'body1' }}
            secondary={moment(notification.created_at).fromNow()}
          />
          <ArrowForwardIcon className={classes.arrowForwardIcon} />
        </ListItem>
      ))}
    </StyledList>
  )
}

NotificationList.propTypes = {
  className: PropTypes.string,
  notifications: PropTypes.array.isRequired,
}

export default NotificationList
