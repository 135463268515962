import * as ReactDOM from 'react-dom'

import { styled } from '@mui/material/styles'
import {
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Dialog,
} from '@mui/material'
import { ThemeProvider, Theme, StyledEngineProvider } from '@mui/material'

import theme from 'theme'

const PREFIX = 'index'

const css = {
  root: `${PREFIX}-root`,
}

const StyledStyledEngineProvider = styled(StyledEngineProvider)(() => ({
  [`& .${css.root}`]: {
    minWidth: 300,
  },
}))

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const IS_REACT_16 = !!ReactDOM.createPortal

type ConfirmationDialogRawProps = {
  content: any
  onOk: () => void
  onCancel: () => void
  okText: string
  cancelText: string
  title: string
  open: boolean
  [x: string]: any
}

const ConfirmationDialogRaw = (props: ConfirmationDialogRawProps) => {
  const { content, onOk, onCancel, okText, cancelText, title, open, ...other } = props

  function handleCancel() {
    onCancel && onCancel()
    other.close()
  }

  function handleOk() {
    onOk && onOk()
    other.close()
  }

  return (
    <Dialog
      className={css.root}
      aria-labelledby="confirmation-dialog-title"
      aria-describedby="alert-dialog-description"
      onClose={handleCancel}
      open={open}
      maxWidth="sm"
      fullWidth
      {...other}
    >
      <DialogTitle id="confirmation-dialog-title">{title}</DialogTitle>
      <DialogContent dividers>
        <DialogContentText id="alert-dialog-description">{content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>{cancelText}</Button>
        <Button onClick={handleOk} variant="contained" color="primary">
          {okText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const destroyFns: any[] = []

export default function confirm(config: any) {
  const div = document.createElement('div')
  document.body.appendChild(div)
  // eslint-disable-next-line no-use-before-define
  let currentConfig = { ...config, close, open: true }

  function destroy(...args: any[]) {
    const unmountResult = ReactDOM.unmountComponentAtNode(div)
    if (unmountResult && div.parentNode) {
      div.parentNode.removeChild(div)
    }
    const triggerCancel = args.some((param) => param && param.triggerCancel)
    if (config.onCancel && triggerCancel) {
      config.onCancel(...args)
    }
    for (let i = 0; i < destroyFns.length; i += 1) {
      const fn = destroyFns[i]
      // eslint-disable-next-line no-use-before-define
      if (fn === close) {
        destroyFns.splice(i, 1)
        break
      }
    }
  }

  function render(props: any) {
    ReactDOM.render(
      <StyledStyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <ConfirmationDialogRaw {...props} />
        </ThemeProvider>
      </StyledStyledEngineProvider>,
      div,
    )
  }

  function close(...args: any[]) {
    currentConfig = {
      ...currentConfig,
      open: false,
    }
    if (IS_REACT_16) {
      render(currentConfig)
    } else {
      destroy(...args)
    }
  }

  function update(newConfig: any) {
    currentConfig = {
      ...currentConfig,
      ...newConfig,
    }
    render(currentConfig)
  }

  render(currentConfig)

  destroyFns.push(close)

  return {
    destroy: close,
    update,
  }
}
