import { useContext, useState, FC, Suspense } from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'
import { useQuery, gql } from '@apollo/client'
import { Helmet } from 'react-helmet'
import { ApolloProvider } from '@apollo/client'

import { Box, CssBaseline, LinearProgress, Toolbar } from '@mui/material'

import AppStoreContext from 'stores'
import { NavBar, TopBar } from './components'
import apolloClient from 'services/apollo'

const PING = gql`
  {
    ping
  }
`

const Ping = () => {
  const { user } = useContext(AppStoreContext)

  const { error } = useQuery(PING, {
    fetchPolicy: 'no-cache',
    pollInterval: 300000, // 5 mins
  })

  if (error) {
    user.logout()
    return <Redirect to="/user/login" />
  }

  return null
}

export const AuthenticatedApp: FC<
  {
    component: any
  } & RouteProps
> = ({ component: Component, ...rest }) => {
  const { user } = useContext(AppStoreContext)

  const [openNavBarMobile, setOpenNavBarMobile] = useState(false)

  const handleNavBarMobileOpen = () => {
    setOpenNavBarMobile(true)
  }
  const handleNavBarMobileClose = () => {
    setOpenNavBarMobile(false)
  }

  if (!user.username && !user.token) {
    return <Redirect to="/user/login" />
  }

  const client = apolloClient({
    headers: {
      authorization: `Bearer ${user.token}`,
    },
  })

  return (
    <Route
      {...rest}
      render={(props: RouteProps) => (
        <ApolloProvider client={client}>
          <Ping />
          <CssBaseline />
          <Helmet titleTemplate="Sirtoko | %s" title="Base" />
          <Box>
            <TopBar onOpenNavBarMobile={handleNavBarMobileOpen} />
            <Box sx={{ display: 'flex' }}>
              <NavBar onMobileClose={handleNavBarMobileClose} openMobile={openNavBarMobile} />
              <Box component="main" sx={{ flexGrow: 1, bgcolor: 'background.d' }}>
                <Toolbar />
                <Suspense fallback={<LinearProgress />}>
                  <Component {...props} />
                </Suspense>
              </Box>
            </Box>
          </Box>
        </ApolloProvider>
      )}
    />
  )
}
