import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { styled } from '@mui/material/styles'
import { Box, Hidden, Drawer, Toolbar } from '@mui/material'

// import AppStoreContext from 'stores'
import { Navigation } from 'components'
import navigationConfig from './navigationConfig'

const Root = styled('div')(() => ({
  height: '100%',
  overflowY: 'auto',
}))

const drawerWidth = 240

const NavBar = (props: any) => {
  const { openMobile, onMobileClose } = props
  // const { user } = useContext(AppStoreContext)

  const location = useLocation()

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  const navbarContent = (
    <Box sx={{ overflow: 'auto' }}>
      <Box
        component="nav"
        sx={{
          padding: (theme) => theme.spacing(2),
          marginTop: (theme) => theme.spacing(2),
        }}
      >
        {navigationConfig.map((list) => (
          <Navigation component="div" key={list.title} pages={list.pages} title={list.title} />
        ))}
      </Box>
    </Box>
  )

  return (
    <Root>
      <Hidden lgUp>
        <Drawer
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
          anchor="left"
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
            },
          }}
        >
          <Toolbar />
          {navbarContent}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
            },
          }}
          variant="permanent"
          anchor="left"
        >
          <Toolbar />
          {navbarContent}
        </Drawer>
      </Hidden>
    </Root>
  )
}

export default NavBar
