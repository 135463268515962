import React from 'react'
import PropTypes from 'prop-types'
import NumberFormat from 'react-number-format'

function CurrentInput(props: any) {
  const { inputRef, onChange, ...other } = props

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values: any) => {
        onChange({
          target: {
            value: values.value,
          },
        })
      }}
      thousandSeparator
      prefix="$ "
    />
  )
}

CurrentInput.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default CurrentInput
