import { styled } from '@mui/material/styles'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { Typography } from '@mui/material'

const PREFIX = 'EmptyList'

const css = {
  root: `${PREFIX}-root`,
  image: `${PREFIX}-image`,
}

const Root = styled('div')(({ theme }) => ({
  [`&.${css.root}`]: {
    textAlign: 'center',
    padding: theme.spacing(3),
  },

  [`& .${css.image}`]: {
    height: 240,
    backgroundImage: 'url("/images/undraw_empty_xct9.svg")',
    backgroundPositionX: 'right',
    backgroundPositionY: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
}))

const EmptyList = (props: any) => {
  const { className, ...rest } = props

  return (
    <Root {...rest} className={clsx(css.root, className)}>
      <div className={css.image} />
      <Typography variant="h4">There's nothing here...</Typography>
    </Root>
  )
}

EmptyList.propTypes = {
  className: PropTypes.string,
}

export default EmptyList
