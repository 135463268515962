import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { withRouter } from 'react-router-dom'

const { NODE_ENV } = process.env
const GA_MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID

const Page = (props: any) => {
  const { title, children, location, staticContext, ...rest } = props

  useEffect(() => {
    if (NODE_ENV !== 'production') {
      return
    }

    if (window.gtag) {
      window.gtag('config', GA_MEASUREMENT_ID, {
        page_path: location.pathname,
        page_name: title,
      })
    }
  }, [title, location])

  return (
    <div {...rest}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {children}
    </div>
  )
}

export default withRouter(Page)
