/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React from 'react'
import { colors } from '@mui/material'
import HomeIcon from '@mui/icons-material/HomeOutlined'
import ListAltIcon from '@mui/icons-material/ListAlt'
import HighlightIcon from '@mui/icons-material/HighlightOutlined'
import ViewModuleIcon from '@mui/icons-material/ViewModule'
import SettingsIcon from '@mui/icons-material/Settings'

import { Label } from 'components'

const navigationConfig = [
  {
    action: '*',
    title: 'Pages',
    pages: [
      {
        action: 'admin:*',
        title: 'Products',
        href: '/products',
        icon: HomeIcon,
        children: [
          {
            action: 'admin:*',
            title: 'Products',
            href: '/products',
          },
          {
            action: 'admin:*',
            title: 'Categories',
            href: '/products/categories',
          },
          {
            action: 'admin:*',
            title: 'Suppliers',
            href: '/products/suppliers',
          },
          {
            action: 'admin:*',
            title: 'BOMs',
            href: '/boms',
          },
          {
            action: 'admin:*',
            title: 'BOM Categories',
            href: '/boms/categories',
          },
        ],
      },
      {
        action: 'admin:*',
        title: 'Lists',
        href: '/lists',
        icon: ListAltIcon,
      },
      {
        action: 'admin:*',
        title: 'Tools',
        icon: HighlightIcon,
        children: [
          {
            action: 'admin:*',
            title: 'Costing',
            href: '/costing',
          },
          {
            action: 'admin:*',
            title: 'Usage',
            href: '/usage',
          },
        ],
      },
      {
        action: 'admin:*',
        title: 'Settings',
        href: '/settings',
        icon: SettingsIcon,
        children: [
          {
            action: 'admin:*',
            title: 'Stores',
            href: '/settings/stores',
          },
        ],
      },
    ],
  },
  {
    action: '*',
    title: 'Support',
    pages: [
      {
        action: '*',
        title: 'Changelog',
        href: '/changelog',
        icon: ViewModuleIcon,
        label: () => <Label color={colors.blue['500']}>v1.0.0</Label>,
      },
    ],
  },
]

export default navigationConfig
