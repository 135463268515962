import { ApolloClient, InMemoryCache } from '@apollo/client'

const client = (config = {}) => {
  return new ApolloClient({
    uri: process.env.REACT_APP_API_URL,
    cache: new InMemoryCache(),
    ...config,
  })
}

export default client
