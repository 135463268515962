import { gql } from '@apollo/client'

export const GET_PRODUCTS = gql`
  query PicketGetProducts($query: ProductsQuery) {
    products(query: $query) {
      id
      name
      category {
        name
      }
      units {
        id
        name
      }
    }
  }
`

export const GET_PRODUCT = gql`
  query GetProduct($productId: ID!) {
    product(id: $productId) {
      id
      name
      units {
        id
        name
      }
      bom {
        product {
          id
          name
        }
        unit {
          id
          name
        }
        quantity
      }
    }
  }
`

export const GET_CATEGORIES = gql`
  {
    productCategories {
      id
      name
    }
  }
`

export const GET_LISTS = gql`
  query productPickerGetLists {
    productLists {
      id
      name
      description
    }
  }
`

export const GET_LIST = gql`
  query productPickerGetList($listId: ID!) {
    productList(id: $listId) {
      items {
        product {
          id
          name
        }
        unit {
          id
          name
        }
        quantity
      }
    }
  }
`

export const GET_COSTS = gql`
  query GetCosting($q: [CostingQuery!]) {
    costing(q: $q) {
      total
      results {
        product {
          id
          name
        }
        cost
      }
    }
  }
`
