import { useContext, useState, useRef } from 'react'
import { Link as RouterLink, useHistory } from 'react-router-dom'

import { AppBar, Box, Badge, Button, IconButton, Toolbar, Hidden } from '@mui/material'
import NotificationsIcon from '@mui/icons-material/NotificationsOutlined'
import InputIcon from '@mui/icons-material/Input'
import MenuIcon from '@mui/icons-material/Menu'
import { styled } from '@mui/material/styles'

import { NotificationsPopover } from 'components'
import AppStoreContext from 'stores'

const Logo = styled('img')(() => ({
  height: '50px',
  maxWidth: 'auto',
  display: 'inline-block',
}))

const TopBar = (props: any) => {
  const { onOpenNavBarMobile, className, ...rest } = props

  const history = useHistory()
  const notificationsRef = useRef(null)
  const [notifications] = useState([])
  const [openNotifications, setOpenNotifications] = useState(false)

  const { user } = useContext(AppStoreContext)

  // useEffect(() => {
  //   let mounted = true

  //   const fetchNotifications = () => {
  //     axios.get('/api/account/notifications').then(response => {
  //       if (mounted) {
  //         setNotifications(response.data.notifications)
  //       }
  //     })
  //   }

  //   fetchNotifications()

  //   return () => {
  //     mounted = false
  //   }
  // }, [])

  const handleLogout = () => {
    user.logout()
    history.push('/user/login')
  }

  const handleNotificationsOpen = () => {
    setOpenNotifications(true)
  }

  const handleNotificationsClose = () => {
    setOpenNotifications(false)
  }

  return (
    <AppBar
      {...rest}
      position="fixed"
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, boxShadow: 'none' }}
    >
      <Toolbar>
        <RouterLink to="/">
          <Logo alt="Sirtoko" src="/resources/images/sirtoko.png" />
        </RouterLink>
        <Box sx={{ flexGrow: 1 }}></Box>
        <Hidden lgDown>
          <IconButton
            color="inherit"
            onClick={handleNotificationsOpen}
            ref={notificationsRef}
            size="large"
            sx={{
              marginLeft: (theme) => theme.spacing(1),
              color: '#fff',
            }}
          >
            <Badge badgeContent={notifications.length} variant="dot">
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <Button
            color="inherit"
            onClick={handleLogout}
            sx={{
              marginLeft: (theme) => theme.spacing(1),
            }}
          >
            <InputIcon
              sx={{
                marginRight: (theme) => theme.spacing(1),
              }}
            />
            Sign out
          </Button>
        </Hidden>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onOpenNavBarMobile} size="large">
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
      <NotificationsPopover
        anchorEl={notificationsRef.current}
        notifications={notifications}
        onClose={handleNotificationsClose}
        open={openNotifications}
      />
    </AppBar>
  )
}

export default TopBar
