import { observer } from 'mobx-react-lite'
import { Link } from 'react-router-dom'

import { styled } from '@mui/material/styles'
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableFooter,
  Typography,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import LaunchIcon from '@mui/icons-material/Launch'

const PREFIX = 'ComponentsList'

const css = {
  table: `${PREFIX}-table`,
  actionCell: `${PREFIX}-actionCell`,
  productCell: `${PREFIX}-productCell`,
  margin: `${PREFIX}-margin`,
}

const StyledTable = styled(Table)(({ theme }) => ({
  [`& .${css.table}`]: {
    tableLayout: 'fixed',
  },

  [`& .${css.actionCell}`]: {
    width: '35%',
    textAlign: 'right',
  },

  [`& .${css.productCell}`]: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    width: '65%',
  },

  [`& .${css.margin}`]: {
    margin: theme.spacing(1),
  },
}))

type ComponentsListProps = {
  store: any
}
const ComponentsList = observer((props: ComponentsListProps) => {
  const { store } = props

  // const state: any = useLocalStore(() => ({}))

  const components = Array.from(store.selected.values())

  // row handlers
  const handleDeleteRow = (id: string) => {
    store.selected.delete(id)
    store.quantities.delete(id)
  }

  const totalCost = components.reduce((acc: number, curr: any) => {
    acc += curr.cost
    return acc
  }, 0.0)

  if (components.length < 1) {
    return null
  }

  return (
    <StyledTable className={css.table}>
      <TableHead>
        <TableRow>
          <TableCell className={css.productCell}>Product</TableCell>
          {
            // <TableCell className={css.costCell}>Cost</TableCell>
          }
          <TableCell className={css.actionCell} />
        </TableRow>
      </TableHead>

      <TableBody>
        {components.map((comp: any) => {
          return (
            <TableRow hover key={comp.product.id} className={css.productCell}>
              <TableCell>
                <Typography variant="subtitle1">{comp.product.name}</Typography>
                <Typography variant="subtitle2">
                  {comp.quantity} {comp.unit.name}
                </Typography>
              </TableCell>
              {
                // <TableCell>$ {comp.cost.toFixed(2)}</TableCell>
              }
              <TableCell className={css.actionCell}>
                <IconButton
                  aria-label="delete"
                  className={css.margin}
                  onClick={() => handleDeleteRow(comp.product.id)}
                  size="large"
                >
                  <DeleteIcon />
                </IconButton>
                <Link to={`/products/${comp.product.id}`} target="_blank">
                  <IconButton aria-label="delete" className={css.margin} size="large">
                    <LaunchIcon />
                  </IconButton>
                </Link>
              </TableCell>
            </TableRow>
          )
        })}
      </TableBody>

      <TableFooter>
        <TableRow>
          <TableCell colSpan={1000} align="right">
            <Typography variant="h5">Total: $ {totalCost.toFixed(2)}</Typography>
          </TableCell>
        </TableRow>
      </TableFooter>
    </StyledTable>
  )
})

export default ComponentsList
