import { useState, forwardRef } from 'react'
import { styled } from '@mui/material/styles'
import { NavLink as RouterLink } from 'react-router-dom'
import clsx from 'clsx'
import { ListItem, Button, Collapse, colors } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'

const PREFIX = 'NavigationListItem'

const css = {
  item: `${PREFIX}-item`,
  itemLeaf: `${PREFIX}-itemLeaf`,
  button: `${PREFIX}-button`,
  buttonLeaf: `${PREFIX}-buttonLeaf`,
  icon: `${PREFIX}-icon`,
  expandIcon: `${PREFIX}-expandIcon`,
  label: `${PREFIX}-label`,
  active: `${PREFIX}-active`,
}

const StyledListItem = styled(ListItem)(({ theme }) => ({
  [`&.${css.item}`]: {
    display: 'block',
    paddingTop: 0,
    paddingBottom: 0,
  },

  [`& .${css.itemLeaf}`]: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
  },

  [`& .${css.button}`]: {
    color: colors.blueGrey[800],
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
  },

  [`& .${css.buttonLeaf}`]: {
    color: colors.blueGrey[800],
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightRegular,
    '&.depth-0': {
      fontWeight: theme.typography.fontWeightMedium,
    },
  },

  [`& .${css.icon}`]: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
  },

  [`& .${css.expandIcon}`]: {
    marginLeft: 'auto',
    height: 16,
    width: 16,
  },

  [`& .${css.label}`]: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
  },

  [`& .${css.active}`]: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    '& $icon': {
      color: theme.palette.primary.main,
    },
  },
}))

const CustomRouterLink = forwardRef((props: any, ref: any) => (
  <div ref={ref} style={{ flexGrow: 1 }}>
    <RouterLink {...props} />
  </div>
))

const NavigationListItem = (props: any) => {
  const {
    title,
    href,
    depth,
    children,
    icon: Icon,
    className,
    open: openProp,
    label: Label,
    ...rest
  } = props

  const [open, setOpen] = useState(openProp)

  const handleToggle = () => {
    setOpen((opn: any) => !opn)
  }

  let paddingLeft = 8

  if (depth > 0) {
    paddingLeft = 32 + 8 * depth
  }

  const style = {
    paddingLeft,
  }

  if (children) {
    return (
      <StyledListItem {...rest} className={clsx(css.item, className)} disableGutters>
        <Button className={css.button} onClick={handleToggle} style={style}>
          {Icon && <Icon className={css.icon} />}
          {title}
          {open ? (
            <ExpandLessIcon className={css.expandIcon} color="inherit" />
          ) : (
            <ExpandMoreIcon className={css.expandIcon} color="inherit" />
          )}
        </Button>
        <Collapse in={open}>{children}</Collapse>
      </StyledListItem>
    )
  }
  return (
    <ListItem {...rest} className={clsx(css.itemLeaf, className)} disableGutters>
      <Button
        activeClassName={css.active}
        className={clsx(css.buttonLeaf, `depth-${depth}`)}
        component={CustomRouterLink}
        exact
        style={style}
        to={href}
      >
        {Icon && <Icon className={css.icon} />}
        {title}
        {Label && (
          <span className={css.label}>
            <Label />
          </span>
        )}
      </Button>
    </ListItem>
  )
}

export default NavigationListItem
