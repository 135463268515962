import React from 'react'
import { AccessControlContext } from 'services'

const useAccessControl = () => {
  const value = React.useContext(AccessControlContext)

  if (!value) {
    throw new Error('useAccessControl must be used within a AccessControlProvider.')
  }

  return value
}

export default useAccessControl
