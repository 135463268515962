import Select from 'react-select'

import { Box, Chip, MenuItem, Paper, TextField, Typography } from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'

function NoOptionsMessage(props: any) {
  const { innerProps, children } = props

  return (
    <Typography
      color="textSecondary"
      sx={(theme) => ({
        padding: theme.spacing(1, 2),
      })}
      {...innerProps}
    >
      {children}
    </Typography>
  )
}

function inputComponent({ inputRef, ...props }: any) {
  return <div ref={inputRef} {...props} />
}

function Control(props: any) {
  const {
    children,
    innerProps,
    innerRef,
    selectProps: { TextFieldProps },
  } = props

  return (
    <TextField
      sx={{
        flexGrow: 1,
        width: '100%',
        backgroundColor: '#fff',
      }}
      InputProps={{
        inputComponent,
        inputProps: {
          sx: {
            display: 'flex',
          },
          ref: innerRef,
          children,
          ...innerProps,
        },
      }}
      {...TextFieldProps}
    />
  )
}

function Option(props: any) {
  const { children, innerProps, innerRef, isFocused, isSelected } = props
  return (
    <MenuItem
      ref={innerRef}
      selected={isFocused}
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400,
      }}
      {...innerProps}
    >
      {children}
    </MenuItem>
  )
}

function Placeholder(props: any) {
  const { innerProps = {}, children } = props

  return (
    <Typography color="textSecondary" {...innerProps}>
      {children}
    </Typography>
  )
}

function SingleValue(props: any) {
  const { children, innerProps } = props

  return <Typography {...innerProps}>{children}</Typography>
}

function ValueContainer(props: any) {
  const { children } = props
  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        flex: 1,
        alignItems: 'center',
        overflow: 'hidden',
      }}
    >
      {children}
    </Box>
  )
}

function MultiValue(props: any) {
  const { children, /*isFocused,*/ removeProps } = props

  // [`& .${css.chipFocused}`]: {
  //   backgroundColor: emphasize(
  //     theme.palette.mode === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
  //     0.08,
  //   ),
  // },

  return (
    <Chip
      tabIndex={-1}
      label={children}
      sx={(theme) => ({
        margin: theme.spacing(0.5, 0.25),
      })}
      onDelete={removeProps.onClick}
      deleteIcon={<CancelIcon {...removeProps} />}
    />
  )
}

function Menu(props: any) {
  const { children, innerProps } = props

  return (
    <Paper
      square
      sx={{
        boxShadow: 3,
        position: 'absolute',
        zIndex: 'modal',
        left: 0,
        right: 0,
      }}
      {...innerProps}
    >
      {children}
    </Paper>
  )
}

export const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
}

const AutoComplete = (props: any) => {
  const {
    label,
    className,
    options,
    FieldProps,
    getOptionLabel,
    getOptionValue,
    defaultValue,
    preOnChange,
    TextFieldProps,
    ...rest
  } = props

  const onChange = (val: any, event: any) => {
    preOnChange && preOnChange(val, event)
    FieldProps.onChange(getOptionValue(val))
  }

  const { onFocus, onBlur, name } = FieldProps

  return (
    <Select
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      defaultValue={defaultValue}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      inputId={name}
      options={options}
      components={components}
      placeholder={null}
      TextFieldProps={{
        variant: 'outlined',
        InputLabelProps: {
          htmlFor: name,
          shrink: true,
        },
        label,
        ...TextFieldProps,
      }}
      {...rest}
    />
  )
}

export default AutoComplete
