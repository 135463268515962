import { styled } from '@mui/material/styles'

import { Card, CardContent, Typography } from '@mui/material'

import Page from 'components/Page'
import LoginForm from './LoginForm'

const StyledPage = styled(Page)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(6, 2),
}))

const Login = () => {
  return (
    <StyledPage title="Login">
      <Card>
        <CardContent>
          <Typography gutterBottom variant="h3">
            Sign in
          </Typography>
          <LoginForm />
        </CardContent>
      </Card>
    </StyledPage>
  )
}

export default Login
