import React, { useContext, FC, Suspense } from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'
import { Observer } from 'mobx-react-lite'
import { ApolloProvider } from '@apollo/client'

import { styled } from '@mui/material/styles'
import { LinearProgress } from '@mui/material'

import AppStoreContext from 'stores'
import client from 'services/apollo'

const StyledMain = styled('main')(({ theme }) => ({
  height: '100%',
  paddingTop: 56,
  [theme.breakpoints.up('sm')]: {
    paddingTop: 64,
  },
}))

export const UnauthenticatedApp: FC<
  {
    component: any
  } & RouteProps
> = ({ component: Component, ...rest }) => {
  const { user } = useContext(AppStoreContext)

  return (
    <Route
      {...rest}
      render={(props: RouteProps) =>
        user && user.authorized ? (
          <Redirect push to="/" />
        ) : (
          <Observer>
            {() => (
              <ApolloProvider client={client()}>
                <StyledMain>
                  <div>
                    <Suspense fallback={<LinearProgress />}>
                      <Component {...props} />
                    </Suspense>
                  </div>
                </StyledMain>
              </ApolloProvider>
            )}
          </Observer>
        )
      }
    />
  )
}
