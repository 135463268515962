import { Typography } from '@mui/material'

const Label = (props: any) => {
  const { variant, color, shape, children, sx, ...rest } = props

  const dynamicStyles: any = {
    border: `1px solid ${color}`,
    color: variant === 'contained' ? '#fff' : color,
    backgroundColor: variant === 'contained' ? color : 'transparent',
  }

  const styles = (theme: any) => ({
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 0,
    flexShrink: 0,
    borderRadius: 1,
    lineHeight: '10px',
    height: 20,
    minWidth: 20,
    whiteSpace: 'nowrap',
    padding: theme.spacing(0.5, 1),
    margin: theme.spacing(0, 0.2),
    ...dynamicStyles,
    ...sx,
  })

  return (
    <Typography variant="overline" sx={styles} {...rest}>
      {children}
    </Typography>
  )
}

export default Label
