import PropTypes from 'prop-types'
import { matchPath, useLocation } from 'react-router-dom'

import clsx from 'clsx'
import { Box, List, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

import useAccessControl from 'utils/useAccessControl'
import { NavigationListItem } from './components'

const PREFIX = 'Navigation'

const css = {
  root: `${PREFIX}-root`,
}

const Root = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(3),
}))

const NavigationList = (props: any) => {
  const { pages, ...rest } = props
  const { can } = useAccessControl()

  return (
    <List>
      {pages.reduce((items: any, page: any) => ChildRoute({ items, page, can, ...rest }), [])}
    </List>
  )
}

NavigationList.propTypes = {
  depth: PropTypes.number,
  pages: PropTypes.array,
}

const ChildRoute = (props: any) => {
  const { items, page, depth, can } = props
  const location = useLocation()

  const canAccess = can(page.action)
  if (!canAccess) {
    return items
  }

  if (page.children) {
    const open = matchPath(location.pathname, {
      path: page.href,
      exact: false,
    })

    items.push(
      <NavigationListItem
        depth={depth}
        icon={page.icon}
        key={page.title}
        label={page.label}
        open={Boolean(open)}
        title={page.title}
      >
        <NavigationList depth={depth + 1} pages={page.children} />
      </NavigationListItem>,
    )
  } else {
    items.push(
      <NavigationListItem
        depth={depth}
        href={page.href}
        icon={page.icon}
        key={page.title}
        label={page.label}
        title={page.title}
      />,
    )
  }

  return items
}

const Navigation = (props: any) => {
  const { title, pages, className, component: Component, ...rest } = props

  return (
    <Root {...rest} className={clsx(css.root, className)}>
      <Component>
        {title && <Typography variant="overline">{title}</Typography>}
        <NavigationList depth={0} pages={pages} />
      </Component>
    </Root>
  )
}

Navigation.propTypes = {
  className: PropTypes.string,
  component: PropTypes.any,
  pages: PropTypes.array.isRequired,
  title: PropTypes.string,
}

Navigation.defaultProps = {
  component: 'nav',
}

export default Navigation
