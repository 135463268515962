import { useRef } from 'react'
import { observer, useLocalStore } from 'mobx-react-lite'
import { useQuery } from '@apollo/client'
import clsx from 'clsx'
import debug from 'debug'

import { styled } from '@mui/material/styles'
import {
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  FormControl,
  FormControlLabel,
  InputLabel,
  OutlinedInput,
  MenuItem,
  Select,
  TextField,
  Paper,
  SelectChangeEvent,
  // colors,
} from '@mui/material'

import { GET_COSTS, GET_PRODUCT } from './queries'
import AutoComplete from './AutoComplete'
import ComponentsList from './ComponentsList'

const PREFIX = 'Picker'

const css = {
  root: `${PREFIX}-root`,
  fields: `${PREFIX}-fields`,
  textField: `${PREFIX}-textField`,
  dense: `${PREFIX}-dense`,
  unitSelect: `${PREFIX}-unitSelect`,
  titleCell: `${PREFIX}-titleCell`,
  submit: `${PREFIX}-submit`,
}

const Root = styled('div')(({ theme }) => ({
  [`& .${css.root}`]: {
    maxWidth: theme.breakpoints.values.lg,
  },

  [`& .${css.fields}`]: {
    padding: theme.spacing(2),
  },

  [`& .${css.textField}`]: {},
  [`& .${css.dense}`]: {},
  [`& .${css.unitSelect}`]: {},

  [`& .${css.titleCell}`]: {
    maxWidth: '150px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },

  [`& .${css.submit}`]: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'flex-end',
    margin: theme.spacing(1, 0),
  },
}))

const log = debug('@sirtoko:ProductPicker')
log.enabled = true

type PickerProps = {
  store: any
  onResults: (result: any[]) => void
  loading: boolean
  products: any[]
  [x: string]: any
}

const Picker = observer((props: PickerProps) => {
  const { store, products, loading, onResults } = props

  const productRef: any = useRef(null)
  const qtyRef: any = useRef(null)

  const state: any = useLocalStore(() => ({
    productId: null,
    unitId: null,
    quantity: null,

    product: null,
    extractBom: false,

    reset() {
      state.productId = null
      state.unitId = null
      state.quantity = null
      state.extractBom = false
      state.product = null

      if (productRef && productRef.current) {
        productRef.current.select.clearValue()
        productRef.current.focus()
      }
      if (qtyRef && qtyRef.current) {
        qtyRef.current.value = ''
      }
    },
  }))

  const { refetch: getBom } = useQuery(GET_PRODUCT, { skip: true })
  const handleExtractBom = () => {
    state.extractBom = !state.extractBom
  }

  const { refetch: getCosting, loading: costingLoading } = useQuery(GET_COSTS, {
    fetchPolicy: 'no-cache',
    skip: true,
  })

  const handleAdd = () => {
    const q: any = [{ product: state.productId, unit: state.unitId, quantity: state.quantity }]
    getCosting({ q }).then((res: any) => {
      const { data } = res
      if (!data || !data.costing) {
        return
      }

      const {
        costing: { results, total },
      } = data
      const cache = results.reduce((acc: any, curr: any) => {
        const { product, cost } = curr
        acc[product.id] = cost
        return acc
      }, {})
      const { product } = state
      let finalResults

      if (state.extractBom) {
        if (!product || product.bom.length < 1) {
          // TODO: gracefully fail
          return
        }
        finalResults = product.bom.map((comp: any) => {
          const { product, unit, quantity } = comp
          const cost = cache[product.id]
          return { product, unit, quantity, cost }
        })
      } else {
        finalResults = [
          {
            product,
            unit: product.units.find((u: any) => u.id === state.unitId),
            quantity: state.quantity,
            cost: total,
          },
        ]
      }

      onResults(finalResults)
      state.reset()
    })
  }

  const productOnChange = (productId: string | null) => {
    if (!productId) {
      return
    }
    state.productId = productId
    getBom({ productId }).then(({ data }: any) => {
      const { product } = data
      if (product) {
        state.product = product
      }
    })
  }

  const qtyOnChange = (event: any) => {
    state.quantity = parseFloat(event.target.value)
  }

  let product
  if (state.productId) {
    product = products.filter((p) => p.id === state.productId)[0]
  }

  let unitId = state.unitId ? state.unitId : ''

  const onChangeUnit = (event: SelectChangeEvent<unknown>) => {
    state.unitId = event.target.value
  }

  return (
    <Root className={css.root}>
      <Paper className={css.fields}>
        <Grid container spacing={3}>
          <Grid item md={6}>
            <AutoComplete
              options={products}
              isLoading={loading}
              selectRef={productRef}
              onChange={productOnChange}
            />
          </Grid>

          <Grid item md={3}>
            <TextField
              label="Quantity"
              type="number"
              variant="outlined"
              className={clsx(css.textField, css.dense)}
              onChange={qtyOnChange}
              fullWidth={true}
              inputRef={qtyRef}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item md={3}>
            <FormControl
              className={clsx(css.unitSelect, css.dense)}
              variant="outlined"
              fullWidth={true}
            >
              <InputLabel htmlFor="unit">Unit</InputLabel>
              <Select
                value={unitId}
                onChange={onChangeUnit}
                input={<OutlinedInput name="unit" />}
                disabled={!product}
              >
                {product &&
                  product.units &&
                  product.units.map((unit: any) => (
                    <MenuItem key={unit.id} value={unit.id}>
                      {unit.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Paper>

      <div className={css.submit}>
        <FormControlLabel
          control={
            <Checkbox
              checked={state.extractBom}
              onChange={handleExtractBom}
              name="extractBom"
              color="primary"
            />
          }
          label="Extract BOM"
        />

        <Button
          color="primary"
          variant="contained"
          onClick={handleAdd}
          disabled={!state.productId || !state.unitId || !state.quantity}
        >
          Add
        </Button>
      </div>

      {costingLoading ? <CircularProgress /> : <ComponentsList store={store} />}
    </Root>
  )
})

export default Picker
